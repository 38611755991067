import {
    ThumbnailFileTypes,
    audioDescFileTypeArr,
    captionFileFormats,
    mainAssetsAudioFileTypes,
    mainAssetsFileTypes,
    mainAssetsVideoFileTypes,
    navXMLFileFormats,
} from "../../constants/appConstant";
import { ErrMsgObject, ErrObject, FileMissingExtErrMsgObject, FileMissingExtErrObject, FileTypeErrMsgObject, FileTypeErrObject, S3ErrMsgObject, S3ErrObject, WarningMsgObject, WarningObject } from "../../interfaces/commonInterface";

function createData(refrenceId: any, mediaTitle: string, fieldName: string, fileType: string) {
    return {
        refrenceId,
        mediaTitle,
        fieldName,
        fileType,
    };
}

function getAllIndexes(arr: any, val: any) {
    let indexes = [],
        i;
    for (i = 0; i < arr.length; i++) if (arr[i] !== undefined && val !== undefined && arr[i] === val) indexes.push(i);
    return indexes;
}

const getArrangedData = (item: any) => {
    let str = `Row ${item?.lineNo + 2} : `;
    let resultStr: any;
    let pendingFieldsArr: any = Object.values(item);
    pendingFieldsArr.shift();
    pendingFieldsArr = pendingFieldsArr.filter((item: any) => item !== "");
    for (let i = 0; i < pendingFieldsArr.length; i++) {
        if (i === 0) {
            if (
                pendingFieldsArr.length === 1 ||
                pendingFieldsArr.length === 2
            ) {
                resultStr = str.concat(`${pendingFieldsArr[i]}`);
            } else {
                resultStr = str.concat(`${pendingFieldsArr[i]}, `);
            }
        } else if (i < pendingFieldsArr.length - 2) {
            resultStr = resultStr.concat(`${pendingFieldsArr[i]}, `);
        } else if (i === pendingFieldsArr.length - 2) {
            resultStr = resultStr.concat(`${pendingFieldsArr[i]}`);
        } else if (i > pendingFieldsArr.length - 2) {
            resultStr = resultStr.concat(` and ${pendingFieldsArr[i]}`);
        }
    }
    return resultStr;
}

const getFileType = (fileName: any) => {
    let fileExt = fileName?.split('.').pop();
    if (mainAssetsAudioFileTypes?.includes(fileExt?.toUpperCase() as any)) {
        return "Audio";
    } else if (mainAssetsVideoFileTypes?.includes(fileExt?.toUpperCase() as any)) {
        return "Video";
    } else if (ThumbnailFileTypes?.includes(fileExt?.toUpperCase() as any)) {
        return "Thumbnail Image";
    } else if (captionFileFormats?.includes(fileExt?.toUpperCase() as any)) {
        return "Caption File";
    } else if (navXMLFileFormats?.includes(fileExt?.toUpperCase() as any)) {
        return "Nav XML";
    }
}

export const checkForDuplicateRefIds = (objData: Array<any>, setDisplayDuplicateRefIdErrMsg: any, setDuplicateRefIdErr: any) => {
    let duplicateRefIdObj: any = {};
    let referenceIdArr: any[] = [];
    // let underscoreRefIdArr: any[] = [];
    objData.forEach((item, index) => {
        if (index >= 0) {
            // if (String(item?.["Reference ID"])?.includes('_')) {
            //   underscoreRefIdArr.push(`Row ${index + 2} : ${item?.["Reference ID"]}`)
            // }
            if (
                referenceIdArr.length !== 0 &&
                referenceIdArr.includes(item?.["Reference ID"])
            ) {
                let duplicateRefIdArr: any[] = [];
                let allFoundIndex = getAllIndexes(
                    referenceIdArr,
                    item?.["Reference ID"]
                );
                duplicateRefIdArr.push([...allFoundIndex, index]);
                if (item?.["Reference ID"] !== undefined) {
                    duplicateRefIdObj[item?.["Reference ID"]] = duplicateRefIdArr;
                }
            }
            referenceIdArr.push(item?.["Reference ID"]);
        }
    });
    // if(underscoreRefIdArr?.length > 0){
    //   setCheckRefIdUnderscoreErr(true);
    //   setDisplayUnderscoreRefIdErrMsg(underscoreRefIdArr);
    // }
    let dummyArr: any[] = [];
    for (let [, value] of Object.entries(duplicateRefIdObj)) {
        let finalStr = `Row : `;
        let arr: any = value;
        let arr2 = arr[0];
        if (arr2?.length > 0) {
            let str = "";
            for (let i = 0; i < arr2.length; i++) {
                if (i === 0) {
                    if (arr2.length === 1 || arr2.length === 2) {
                        str = str.concat(`${Number(arr2[i] + 2)}`);
                    } else {
                        str = str.concat(`${Number(arr2[i] + 2)}, `);
                    }
                } else if (i < arr2.length - 2) {
                    str = str.concat(`${Number(arr2[i] + 2)}, `);
                } else if (i === arr2.length - 2) {
                    str = str.concat(`${Number(arr2[i] + 2)}`);
                } else if (i > arr2.length - 2) {
                    str = str.concat(` and ${Number(arr2[i] + 2)}`);
                }
            }
            finalStr = finalStr.concat(str);
            dummyArr.push(finalStr);
        }
    }
    setDisplayDuplicateRefIdErrMsg(dummyArr);

    if (Object.keys(duplicateRefIdObj)?.length > 0) {
        setDuplicateRefIdErr(true);
        return false;
    } else {
        return true;
    }
    // else if (underscoreRefIdArr?.length > 0) {
    //   return false;
    // } 
}

export const checkForDuplicateNames = (objData: Array<any>, fieldName: any, displayDuplicateFileNameErrMsg: any, setDisplayDuplicateFileNameErrMsg: any) => {
    let duplicateFileNamesObj: any = {};
    let fileNamesArr: any[] = [];
    objData.forEach((item, index) => {
        if (index >= 0 && item?.[fieldName]) {
            if (
                fileNamesArr.length !== 0 &&
                fileNamesArr.includes(item?.[fieldName])
            ) {
                let duplicateFileNamesArr: any[] = [];
                let allFoundIndex = getAllIndexes(fileNamesArr, item?.[fieldName]);
                duplicateFileNamesArr.push([...allFoundIndex, index]);
                duplicateFileNamesObj[item?.[fieldName]] = duplicateFileNamesArr;
            }
            fileNamesArr.push(item?.[fieldName]);
        }
    });
    let dummyArr: any[] = [];
    for (let [key, value] of Object.entries(duplicateFileNamesObj)) {
        let finalStr = `${key} : Row `;
        let arr: any = value;
        let arr2 = arr[0];
        if (arr2?.length > 0) {
            let str = "";
            for (let i = 0; i < arr2.length; i++) {
                if (i === 0) {
                    if (arr2.length === 1 || arr2.length === 2) {
                        str = str.concat(`${Number(arr2[i] + 2)}`);
                    } else {
                        str = str.concat(`${Number(arr2[i] + 2)}, `);
                    }
                } else if (i < arr2.length - 2) {
                    str = str.concat(`${Number(arr2[i] + 2)}, `);
                } else if (i === arr2.length - 2) {
                    str = str.concat(`${Number(arr2[i] + 2)}`);
                } else if (i > arr2.length - 2) {
                    str = str.concat(` and ${Number(arr2[i] + 2)}`);
                }
            }
            finalStr = finalStr.concat(str);
            dummyArr.push(finalStr);
        }
    }

    if (displayDuplicateFileNameErrMsg?.length !== 0) {
        displayDuplicateFileNameErrMsg?.forEach((item: any) => {
            let searchResultIndex: any = dummyArr?.findIndex((dummyItem: any) => {
                return (item === dummyItem)
            })
            if (searchResultIndex !== -1) {
                dummyArr.splice(searchResultIndex, 1)
            }
        })
    }
    setDisplayDuplicateFileNameErrMsg((previous: any) => {
        return [...previous, ...dummyArr];
    });

    if (Object.keys(duplicateFileNamesObj)?.length > 0) {
        return false;
    } else {
        return true;
    }
}

export const checkForCaptionFileNameRowWise = (
    fileNameArrObj: any,
    singleCaptionFileName: any,
    condition: any = ''
) => {
    let foundIndexesArr: any[] = [];
    for (let [key, value] of Object.entries(fileNameArrObj)) {
        let captionArr: any = value;
        if (captionArr?.length > 0) {
            let foundIndex = captionArr.findIndex((item: any) => {
                return item === singleCaptionFileName;
            });
            if (foundIndex !== -1) {
                foundIndexesArr.push(Number(key));
            }
        }
    }
    if (foundIndexesArr?.length > 0) {
        if (condition === 'getAllIndexes') {
            return foundIndexesArr;
        } else {
            return false;
        }
    } else {
        return true;
    }
};

export const checkForDuplicateCaptionFileNames = (objData: Array<any>, fieldName: any, setDisplayDuplicateFileNameErrMsg: any) => {
    let duplicateFileNamesObj: any = {};
    // let fileNamesArr: any[] = [];
    let fileNameArrObj: any = {};
    objData.forEach((item, index) => {
        if (index >= 0 && item?.[fieldName]) {
            let captionFileNames = item?.[fieldName];
            let splittedCaption = captionFileNames?.split(";").map(function (value: any) {
                return value.trim();
            });
            splittedCaption = splittedCaption.filter((item: any) => {
                return item !== "";
            });
            let uniqueCaptionArr: any[] = [];
            splittedCaption.forEach((singleCaptionFileName: any) => {
                if (
                    Object.keys(fileNameArrObj)?.length !== 0 &&
                    !checkForCaptionFileNameRowWise(fileNameArrObj, singleCaptionFileName)
                ) {
                    let duplicateFileNamesArr: any[] = [];
                    let allFoundIndex: any = checkForCaptionFileNameRowWise(
                        fileNameArrObj,
                        singleCaptionFileName,
                        'getAllIndexes'
                    );
                    duplicateFileNamesArr.push([...allFoundIndex, index]);
                    duplicateFileNamesObj[singleCaptionFileName] = duplicateFileNamesArr;
                }
                uniqueCaptionArr.push(singleCaptionFileName)
                fileNameArrObj[index] = uniqueCaptionArr;
            })
        }
    });
    let dummyArr: any[] = [];
    for (let [key, value] of Object.entries(duplicateFileNamesObj)) {
        let finalStr = `${key} : Row `;
        let arr: any = value;
        let arr2 = arr[0];
        if (arr2?.length > 0) {
            let str = "";
            for (let i = 0; i < arr2.length; i++) {
                if (i === 0) {
                    if (arr2.length === 1 || arr2.length === 2) {
                        str = str.concat(`${Number(arr2[i] + 2)}`);
                    } else {
                        str = str.concat(`${Number(arr2[i] + 2)}, `);
                    }
                } else if (i < arr2.length - 2) {
                    str = str.concat(`${Number(arr2[i] + 2)}, `);
                } else if (i === arr2.length - 2) {
                    str = str.concat(`${Number(arr2[i] + 2)}`);
                } else if (i > arr2.length - 2) {
                    str = str.concat(` and ${Number(arr2[i] + 2)}`);
                }
            }
            finalStr = finalStr.concat(str);
            dummyArr.push(finalStr);
        }
    }
    setDisplayDuplicateFileNameErrMsg((previous: any) => {
        return [
            ...previous,
            ...dummyArr,
        ]
    });

    if (Object.keys(duplicateFileNamesObj)?.length > 0) {
        return false;
    } else {
        return true;
    }
}

export const checkForRequiredFields = (objData: Array<any>, setErrMsg: any) => {
    let errObjArr: ErrObject[] = [];
    objData.forEach((item, index) => {
        if (index >= 0) {
            let errObj: ErrObject = {};
            if (item["Media Title"] === undefined) {
                errObj["index"] = index;
                errObj["mediaTitleEmpty"] = true;
            }
            if (item["Business Unit"] === undefined) {
                errObj["index"] = index;
                errObj["bUnitEmpty"] = true;
            }
            if (item["File Type"] === undefined) {
                errObj["index"] = index;
                errObj["fileTypeError"] = true;
            }
            if (item["Filename"] === undefined) {
                errObj["index"] = index;
                errObj["fileNameError"] = true;
            }
            errObjArr.push(errObj);
        }
    });
    // eslint-disable-next-line array-callback-return
    let errMsgArr: (ErrMsgObject | undefined)[] = errObjArr.map((item) => {
        if (Object.keys(item).length !== 0) {
            return {
                lineNo: item.index,
                mediaTitleMsg: item.mediaTitleEmpty ? "Media Title" : "",
                bUnitMsg: item.bUnitEmpty ? "Business Unit" : "",
                fileNameMsg: item.fileNameError ? "File Name" : "",
                fileTypeMsg: item.fileTypeError ? "File Type" : "",
            };
        }
    });

    let singleErrMsg: (ErrMsgObject | undefined)[] = errMsgArr.filter(
        (item) => {
            if (item !== undefined) {
                return true;
            }
            return false;
        }
    );
    setErrMsg(singleErrMsg);
    if (singleErrMsg.length === 0) {
        return true;
    } else {
        return false;
    }
};

export const checkForRequiredFileFormats = (objData: Array<any>, setCheckMetadataFileTypesErr: any, setDisplayFileTypeErrMsg: any, setCheckMetadataFileExtErr: any, setDisplayFileExtErrMsg: any) => {
    let fileTypeErrArray: any[] = [];
    let fileExtErrArray: any[] = [];
    let fileTypeErrObjArr: FileTypeErrObject[] = [];
    let fileExtErrObjArr: FileMissingExtErrObject[] = [];
    objData.forEach((item, index) => {
        let fileTypeErrObj: FileTypeErrObject = {};
        let fileExtErrObj: FileMissingExtErrObject = {};
        let mainAssetFileType = String(item["Filename"])?.split(".").pop();
        let ThumbnailFileType = String(item["Thumbnail FileName"])?.split(".").pop();
        let navXMLFileType = String(item["navXML FileName"])?.split(".").pop();
        let audioDescFileType = String(item["AudioDescriptor FileName"])
            ?.split(".")
            .pop();
        let captionFileType = String(item["Caption FileNames"]);
        let captionFileTypeArr = captionFileType?.split(";");
        captionFileTypeArr = captionFileTypeArr?.filter((item: any) => {
            return item?.trim() !== ""
        })
        let otherFileType = String(item["Other FileNames"]);
        let otherFileTypeArr = otherFileType?.split(";");
        otherFileTypeArr = otherFileTypeArr?.filter((item: any) => {
            return item?.trim() !== ""
        })

        if (captionFileType && captionFileTypeArr.length > 0) {
            captionFileTypeArr.forEach((item: any) => {
                let captionFileExtension = item?.split(".").pop();
                if (captionFileExtension !== "undefined" && captionFileExtension === String(item)) {
                    fileExtErrObj["index"] = index;
                    fileExtErrObj["captionExtErr"] = true;
                } else if (
                    captionFileExtension !== "undefined" &&
                    !captionFileFormats.includes(captionFileExtension?.trim()?.toUpperCase() as any)
                ) {
                    fileTypeErrObj["index"] = index;
                    fileTypeErrObj["captionfileTypeErr"] = true;
                }
            });
        }

        if (otherFileType && otherFileTypeArr.length > 0) {
            otherFileTypeArr.forEach((item: any) => {
                let otherFileExtension = item?.split(".").pop();
                if (otherFileExtension !== "undefined" && otherFileExtension === String(item)) {
                    fileExtErrObj["index"] = index;
                    fileExtErrObj["otherExtErr"] = true;
                }
            });
        }

        if (mainAssetFileType !== "undefined" && mainAssetFileType === String(item["Filename"])) {
            fileExtErrObj["index"] = index;
            fileExtErrObj["fileNameExtErr"] = true;
        } else if (
            mainAssetFileType !== "undefined" &&
            !mainAssetsFileTypes.includes(mainAssetFileType?.toUpperCase() as any)
        ) {
            fileTypeErrObj["index"] = index;
            fileTypeErrObj["fileNameFileTypeErr"] = true;
        }

        if (ThumbnailFileType !== "undefined" && ThumbnailFileType === String(item["Thumbnail FileName"])) {
            fileExtErrObj["index"] = index;
            fileExtErrObj["thumbnailExtErr"] = true;
        } else if (
            ThumbnailFileType !== "undefined" &&
            !ThumbnailFileTypes.includes(ThumbnailFileType?.toUpperCase() as any)
        ) {
            fileTypeErrObj["index"] = index;
            fileTypeErrObj["thumbnailFileTypeErr"] = true;
        }

        if (audioDescFileType !== "undefined" && audioDescFileType === String(item["AudioDescriptor FileName"])) {
            fileExtErrObj["index"] = index;
            fileExtErrObj["audioDescExtErr"] = true;
        } else if (
            audioDescFileType !== "undefined" &&
            !audioDescFileTypeArr.includes(audioDescFileType?.toUpperCase() as any)
        ) {
            fileTypeErrObj["index"] = index;
            fileTypeErrObj["audioDescFileTypeErr"] = true;
        }

        if (navXMLFileType !== "undefined" && navXMLFileType === String(item["navXML FileName"])) {
            fileExtErrObj["index"] = index;
            fileExtErrObj["navXMLExtErr"] = true;
        } else if (
            navXMLFileType !== "undefined" &&
            !navXMLFileFormats.includes(navXMLFileType?.toUpperCase() as any)
        ) {
            fileTypeErrObj["index"] = index;
            fileTypeErrObj["navXMLFileTypeErr"] = true;
        }
        fileTypeErrObjArr.push(fileTypeErrObj);
        fileExtErrObjArr.push(fileExtErrObj);
    });

    let fileTypeErrMsgArr: (FileTypeErrMsgObject | undefined)[] =
        fileTypeErrObjArr.map((item) => {
            if (Object.keys(item).length !== 0) {
                return {
                    lineNo: item.index,
                    fileNameFileTypeErrMsg: item.fileNameFileTypeErr ? "Filename" : "",
                    thumbnailFileTypeErrMsg: item.thumbnailFileTypeErr
                        ? "Thumbnail"
                        : "",
                    audioDescFileTypeErrMsg: item.audioDescFileTypeErr ? "AD" : "",
                    navXMLFileTypeErrMsg: item.navXMLFileTypeErr ? "navXML" : "",
                    captionfileTypeErrMsg: item.captionfileTypeErr
                        ? "Caption FileNames"
                        : "",
                };
            }
            return undefined
        }).filter(Boolean);

    let fileExtErrMsgArr: (FileMissingExtErrMsgObject | undefined)[] =
        fileExtErrObjArr.map((item) => {
            if (Object.keys(item).length !== 0) {
                return {
                    lineNo: item.index,
                    fileNameExtErrMsg: item.fileNameExtErr ? "Filename" : "",
                    thumbnailExtErrMsg: item.thumbnailExtErr
                        ? "Thumbnail"
                        : "",
                    audioDescExtErrMsg: item.audioDescExtErr ? "AD" : "",
                    navXMLExtErrMsg: item.navXMLExtErr ? "navXML" : "",
                    captionExtErrMsg: item.captionExtErr
                        ? "Caption FileNames"
                        : "",
                    otherExtErrMsg: item.otherExtErr
                        ? "Other FileNames"
                        : "",
                };
            }
            return undefined
        }).filter(Boolean);

    fileTypeErrArray = fileTypeErrMsgArr.map((item: any) => {
        if (item) {
            let resultStr: any = getArrangedData(item);
            return resultStr
        }
        return undefined
    }).filter(Boolean);

    fileExtErrArray = fileExtErrMsgArr.map((item: any) => {
        if (item) {
            let resultStr2: any = getArrangedData(item);
            return resultStr2
        }
        return undefined
    }).filter(Boolean);

    fileTypeErrArray = fileTypeErrArray.filter(
        (item: any) => item !== undefined
    );

    fileExtErrArray = fileExtErrArray.filter(
        (item: any) => item !== undefined
    );
    let falseCondition: boolean = false;
    if (fileTypeErrArray.length > 0) {
        setCheckMetadataFileTypesErr(true);
        setDisplayFileTypeErrMsg(fileTypeErrArray);
        falseCondition = true;
    }
    if (fileExtErrArray.length > 0) {
        setCheckMetadataFileExtErr(true);
        setDisplayFileExtErrMsg(fileExtErrArray);
        falseCondition = true;
    }
    if (falseCondition === true) {
        return false;
    } else {
        return true;
    }
};

export const checkForRelatedAssetsFields = (objData: Array<any>, setWarningMsg: any) => {
    let warningObjArr: WarningObject[] = [];
    objData.forEach((item, index) => {
        if (index >= 0) {
            let warningObj: WarningObject = {};
            if (item["Thumbnail FileName"] === undefined) {
                warningObj["index"] = index;
                warningObj["thumbnailFilenameEmpty"] = true;
            }
            if (item["AudioDescriptor FileName"] === undefined) {
                warningObj["index"] = index;
                warningObj["AudioDescriptorFileNameEmpty"] = true;
            }
            if (item["navXML FileName"] === undefined) {
                warningObj["index"] = index;
                warningObj["navXMLFileNameEmpty"] = true;
            }
            if (item["Caption FileNames"] === undefined) {
                warningObj["index"] = index;
                warningObj["captionFileNamesEmpty"] = true;
            }
            warningObjArr.push(warningObj);
        }
    });
    // eslint-disable-next-line array-callback-return
    let warningMsgArr: (WarningMsgObject | undefined)[] = warningObjArr.map(
        (item) => {
            if (Object.keys(item).length !== 0) {
                return {
                    lineNo: item.index,
                    thumbnailFilenameMsg: item.thumbnailFilenameEmpty
                        ? "Thumbnail"
                        : "",
                    AudioDescriptorFileMsg: item.AudioDescriptorFileNameEmpty
                        ? "AD"
                        : "",
                    navXMLFileNameMsg: item.navXMLFileNameEmpty ? "navXML" : "",
                    captionFileNamesMsg: item.captionFileNamesEmpty
                        ? "Caption Filenames"
                        : "",
                };
            }
            return undefined
        }
    ).filter(Boolean);

    let singlewarningMsg: (WarningMsgObject | undefined)[] =
        warningMsgArr.filter((item) => {
            if (item !== undefined) {
                return true;
            }
            return false;
        });
    setWarningMsg(singlewarningMsg);
    if (singlewarningMsg.length === 0) {
        return true;
    } else {
        return false;
    }
};

export const checkForS3Locations = (objData: Array<any>, setTotalS3UploadAssets: any, setDisplayS3FileNamesErrMsg: any) => {
    let s3FilesErrArray: any[] = [];
    let s3FilesErrObjArr: S3ErrObject[] = [];
    let totalS3UploadFields: number = 0;
    let urlProtocol = objData[0]?.Filename?.includes("s3://") || objData[0]?.Filename?.includes("S3://") ? "s3" : "https"
    objData.forEach((item, index) => {
        let s3FilesErrObj: S3ErrObject = {};
        const fields = [
            { key: "Filename", type: "s3FileNameErr" },
            { key: "Thumbnail FileName", type: "s3ThumbnailFileErr" },
            { key: "navXML FileName", type: "s3navXMLFileErr" },
            { key: "AudioDescriptor FileName", type: "s3AudioDescFileErr" }
        ];

        fields.forEach(field => {
            const fieldValue = String(item[field.key]);
            if (item.hasOwnProperty(field.key)) {
                if (!(fieldValue.includes(urlProtocol) || fieldValue.includes(urlProtocol.toUpperCase()))) {
                    s3FilesErrObj.index = index;
                    s3FilesErrObj[field.type] = true;
                } else {
                    totalS3UploadFields++;
                }
            }
        });

        const captionFileType = String(item["Caption FileNames"]);
        const captionFileTypeArr = item.hasOwnProperty("Caption FileNames") ? captionFileType.split(";").filter(item => item.trim() !== "") : []

        if (captionFileTypeArr.length > 0) {
            captionFileTypeArr.forEach(captionFile => {
                if (!(captionFile.includes(urlProtocol) || captionFile.includes(urlProtocol.toUpperCase()))) {
                    s3FilesErrObj.index = index;
                    s3FilesErrObj.s3CaptionFileErr = true;
                } else {
                    totalS3UploadFields++;
                }
            })
        };

        const otherFileType = String(item["Other FileNames"]);
        const otherFileTypeArr = item.hasOwnProperty("Other FileNames") ? otherFileType.split(";").filter(item => item.trim() !== "") : []

        if (otherFileTypeArr.length > 0) {
            otherFileTypeArr.forEach(otherFile => {
                if (!(otherFile.includes(urlProtocol) || otherFile.includes(urlProtocol.toUpperCase()))) {
                    s3FilesErrObj.index = index;
                    s3FilesErrObj.s3OtherFileErr = true;
                } else {
                    totalS3UploadFields++;
                }
            })
        };
        s3FilesErrObjArr.push(s3FilesErrObj);
    });

    setTotalS3UploadAssets(totalS3UploadFields);

    let s3FilesErrMsgArr: (S3ErrMsgObject | undefined)[] = s3FilesErrObjArr.map(
        (item) => {
            if (Object.keys(item).length !== 0) {
                return {
                    lineNo: item.index,
                    s3FileNameErrMsg: item.s3FileNameErr ? "Filename" : "",
                    s3ThumbnailFileErrMsg: item.s3ThumbnailFileErr ? "Thumbnail" : "",
                    s3AudioDescFileErrMsg: item.s3AudioDescFileErr ? "AD" : "",
                    s3navXMLFileErrMsg: item.s3navXMLFileErr ? "navXML" : "",
                    s3CaptionFileErrMsg: item.s3CaptionFileErr
                        ? "Caption FileNames"
                        : "",
                    s3OtherFileErrMsg: item.s3OtherFileErr
                        ? "Other FileNames"
                        : "",
                };
            }
            return undefined
        }
    ).filter(Boolean);

    s3FilesErrArray = s3FilesErrMsgArr.map((item: any) => {
        if (item) {
            let str = `Row ${item?.lineNo + 2} : `;
            let resultStr: any;
            let pendingFieldsArr: any = Object.values(item);
            pendingFieldsArr.shift();
            pendingFieldsArr = pendingFieldsArr.filter((item: any) => item !== "");
            for (let i = 0; i < pendingFieldsArr.length; i++) {
                if (i === 0) {
                    if (
                        pendingFieldsArr.length === 1 ||
                        pendingFieldsArr.length === 2
                    ) {
                        resultStr = str.concat(`${pendingFieldsArr[i]}`);
                    } else {
                        resultStr = str.concat(`${pendingFieldsArr[i]}, `);
                    }
                } else if (i < pendingFieldsArr.length - 2) {
                    resultStr = resultStr.concat(`${pendingFieldsArr[i]}, `);
                } else if (i === pendingFieldsArr.length - 2) {
                    resultStr = resultStr.concat(`${pendingFieldsArr[i]}`);
                } else if (i > pendingFieldsArr.length - 2) {
                    resultStr = resultStr.concat(` and ${pendingFieldsArr[i]}`);
                }
            }
            return resultStr;
        }
        return undefined
    }).filter(Boolean);

    s3FilesErrArray = s3FilesErrArray.filter((item: any) => item !== undefined);

    if (s3FilesErrArray.length > 0) {
        setDisplayS3FileNamesErrMsg(s3FilesErrArray);
        return false;
    } else {
        return true;
    }
};

export const checkForNonS3Locations = (objData: Array<any>, setDisplayNonS3FileNamesErrMsg: any) => {
    let nonS3FilesErrArray: any[] = [];
    let nonS3FilesErrObjArr: S3ErrObject[] = [];
    let urlProtocol = objData[0]?.Filename?.includes("s3://") || objData[0]?.Filename?.includes("S3://") ? "s3" : "https"
    objData.forEach((item, index) => {
        let s3FilesErrObj: S3ErrObject = {};
        let mainAssetFile = String(item["Filename"]);
        let ThumbnailFile = String(item["Thumbnail FileName"]);
        let navXMLFile = String(item["navXML FileName"]);
        let audioDescFile = String(item["AudioDescriptor FileName"]);
        let captionFileType = String(item["Caption FileNames"]);
        let captionFileTypeArr = captionFileType?.split(";");
        captionFileTypeArr = captionFileTypeArr?.filter((item: any) => {
            return item !== ""
        })
        let otherFileType = String(item["Other FileNames"]);
        let otherFileTypeArr = otherFileType?.split(";");
        otherFileTypeArr = otherFileTypeArr?.filter((item: any) => {
            return item !== ""
        })

        if (captionFileType && captionFileTypeArr.length > 0) {
            captionFileTypeArr.forEach((item: any) => {
                let captionFile = item;

                if (
                    captionFile &&
                    (captionFile.includes(urlProtocol) || captionFile.includes(urlProtocol.toUpperCase()))
                ) {
                    s3FilesErrObj["index"] = index;
                    s3FilesErrObj["s3CaptionFileErr"] = true;
                }
            });
        }

        if (otherFileType && otherFileTypeArr.length > 0) {
            otherFileTypeArr.forEach((item: any) => {
                let otherFile = item;

                if (
                    otherFile &&
                    (otherFile.includes(urlProtocol) || otherFile.includes(urlProtocol.toUpperCase()))
                ) {
                    s3FilesErrObj["index"] = index;
                    s3FilesErrObj["s3OtherFileErr"] = true;
                }
            });
        }

        if (
            mainAssetFile &&
            (mainAssetFile.includes(urlProtocol) || mainAssetFile.includes(urlProtocol.toUpperCase()))
        ) {
            s3FilesErrObj["index"] = index;
            s3FilesErrObj["s3FileNameErr"] = true;
        }
        if (
            ThumbnailFile &&
            (ThumbnailFile.includes(urlProtocol) || ThumbnailFile.includes(urlProtocol.toUpperCase()))
        ) {
            s3FilesErrObj["index"] = index;
            s3FilesErrObj["s3ThumbnailFileErr"] = true;
        }
        if (
            audioDescFile &&
            (audioDescFile.includes(urlProtocol) || audioDescFile.includes(urlProtocol.toUpperCase()))
        ) {
            s3FilesErrObj["index"] = index;
            s3FilesErrObj["s3AudioDescFileErr"] = true;
        }
        if (
            navXMLFile &&
            (navXMLFile.includes(urlProtocol) || navXMLFile.includes(urlProtocol.toUpperCase()))
        ) {
            s3FilesErrObj["index"] = index;
            s3FilesErrObj["s3navXMLFileErr"] = true;
        }
        nonS3FilesErrObjArr.push(s3FilesErrObj);
    });

    let s3FilesErrMsgArr: (S3ErrMsgObject | undefined)[] = nonS3FilesErrObjArr.map(
        (item) => {
            if (Object.keys(item).length !== 0) {
                return {
                    lineNo: item.index,
                    s3FileNameErrMsg: item.s3FileNameErr ? "Filename" : "",
                    s3ThumbnailFileErrMsg: item.s3ThumbnailFileErr ? "Thumbnail" : "",
                    s3AudioDescFileErrMsg: item.s3AudioDescFileErr ? "AD" : "",
                    s3navXMLFileErrMsg: item.s3navXMLFileErr ? "navXML" : "",
                    s3CaptionFileErrMsg: item.s3CaptionFileErr
                        ? "Caption"
                        : "",
                    s3OtherFileErrMsg: item.s3OtherFileErr
                        ? "Other FileNames"
                        : "",
                };
            }
            return undefined
        }
    ).filter(Boolean);

    nonS3FilesErrArray = s3FilesErrMsgArr.map((item: any) => {
        if (item) {
            let str = `Row ${item?.lineNo + 2} : `;
            let resultStr: any;
            let pendingFieldsArr: any = Object.values(item);
            pendingFieldsArr.shift();
            pendingFieldsArr = pendingFieldsArr.filter((item: any) => item !== "");
            for (let i = 0; i < pendingFieldsArr.length; i++) {
                if (i === 0) {
                    if (
                        pendingFieldsArr.length === 1 ||
                        pendingFieldsArr.length === 2
                    ) {
                        resultStr = str.concat(`${pendingFieldsArr[i]}`);
                    } else {
                        resultStr = str.concat(`${pendingFieldsArr[i]}, `);
                    }
                } else if (i < pendingFieldsArr.length - 2) {
                    resultStr = resultStr.concat(`${pendingFieldsArr[i]}, `);
                } else if (i === pendingFieldsArr.length - 2) {
                    resultStr = resultStr.concat(`${pendingFieldsArr[i]}`);
                } else if (i > pendingFieldsArr.length - 2) {
                    resultStr = resultStr.concat(` and ${pendingFieldsArr[i]}`);
                }
            }
            return resultStr;
        }
        return undefined
    }).filter(Boolean);

    nonS3FilesErrArray = nonS3FilesErrArray.filter((item: any) => item !== undefined);

    if (nonS3FilesErrArray.length > 0) {
        setDisplayNonS3FileNamesErrMsg(nonS3FilesErrArray);
        return false;
    } else {
        return true;
    }
};

export const checkForFileStatus = (caption_status: any) => {
    let captionFileStatusArr = caption_status?.split(",");
    let statusArrResult = captionFileStatusArr?.map((item: any) => {
        if (item.split(".")[1].includes('completed')) {
            return true;
        } else {
            return false;
        }
    });
    let fountIndex = statusArrResult?.findIndex((item: any) => {
        return item === false;
    });
    if (fountIndex === -1) {
        return true;
    } else {
        return false;
    }
};

export const createCSVDataInStore = (objData: any[], from: any = '') => {
    let processingObjArr: any[] = [];
    objData.forEach((item: any) => {
        let filename = item["Filename"] || item["filename"];
        let thumbnailFilename = item["Thumbnail FileName"] || item["thumbnailFilename"];
        let audioDescriptorFilename = item["AudioDescriptor FileName"] || item["audioDescriptorFilename"];
        let navXMLFileName = item["navXML FileName"] || item["navXMLFileName"]
        let captionFileNames = item["Caption FileNames"] || item["captionFileNames"];
        let otherFileNames = item["Other FileNames"] || item["otherFileNames"];
        let mediaTitleValue = item["Media Title"] || item["mediaTitle"];
        let refernceId = item["Reference ID"] || item["refrenceId"]
        if (filename) {
            let fileType: any = getFileType(filename)
            processingObjArr.push(
                createData(refernceId, mediaTitleValue, filename, fileType)
            );
        }
        if (thumbnailFilename) {
            processingObjArr.push(
                createData(
                    refernceId,
                    mediaTitleValue,
                    thumbnailFilename,
                    "Thumbnail Image",
                )
            );
        }
        if (audioDescriptorFilename) {
            processingObjArr.push(
                createData(
                    refernceId,
                    mediaTitleValue,
                    audioDescriptorFilename,
                    "AD",
                )
            );
        }
        if (navXMLFileName) {
            processingObjArr.push(
                createData(
                    refernceId,
                    mediaTitleValue,
                    navXMLFileName,
                    "Nav XML",
                )
            );
        }
        if (captionFileNames) {
            let captionFileType = captionFileNames;
            let captionFileTypeArr = captionFileType?.split(";");
            captionFileTypeArr = captionFileTypeArr.filter((item: any) => {
                return item?.trim() !== "";
            });
            captionFileTypeArr.forEach((item: any) => {
                let singleCaptionFile = item?.trim();
                processingObjArr.push(
                    createData(
                        refernceId,
                        mediaTitleValue,
                        singleCaptionFile,
                        "Caption File",
                    )
                );
            });
        }
        if (otherFileNames) {
            let otherFileType = otherFileNames;
            let otherFileTypeArr = otherFileType?.split(";");
            otherFileTypeArr = otherFileTypeArr.filter((item: any) => {
                return item?.trim() !== "";
            });
            otherFileTypeArr.forEach((item: any) => {
                let singleOtherFile = item?.trim();
                let fileType: any = getFileType(singleOtherFile)
                processingObjArr.push(
                    createData(
                        refernceId,
                        mediaTitleValue,
                        singleOtherFile,
                        fileType
                    )
                );
            });
        }
    });
    return processingObjArr
}

export const updateErrMsg = (errMsg: any, setDisplayMsg: any) => {
    let errArray = errMsg.map((err: any) => {
        let finalStr = `Row ${Number(err?.lineNo) + 2} : `;
        let dummyArr: any = [];
        for (let [key] of Object.entries(err)) {
            if (key !== "lineNo" && err[key] !== "") {
                dummyArr.push(err[key]);
            }
        }
        if (dummyArr?.length > 0) {
            let str = "";
            for (let i = 0; i < dummyArr.length; i++) {
                if (i === 0) {
                    if (dummyArr.length === 1) {
                        str = str.concat(`${dummyArr[i]}.`);
                    } else if (dummyArr.length === 2) {
                        str = str.concat(`${dummyArr[i]}`);
                    } else {
                        str = str.concat(`${dummyArr[i]}, `);
                    }
                } else if (i < dummyArr.length - 2) {
                    str = str.concat(`${dummyArr[i]}, `);
                } else if (i === dummyArr.length - 2) {
                    str = str.concat(`${dummyArr[i]}`);
                } else if (i > dummyArr.length - 2) {
                    str = str.concat(` and ${dummyArr[i]}.`);
                }
            }
            finalStr = finalStr.concat(str);
            return finalStr;
        }
        return ""
    });

    if (errMsg !== undefined && Object.keys(errMsg).length !== 0) {
        setDisplayMsg(errArray);
    } else if (
        errMsg === undefined ||
        (typeof errMsg === "object" && Object.keys(errMsg).length === 0)
    ) {
        setDisplayMsg("Your xlsx file has been successfully uploaded");
    }
};

export const updateWarningMsg = (warningMsg: any, setWarningDisplayMsg: any) => {
    let warningArray = warningMsg.map((err: any) => {
        let finalStr = `Row ${Number(err?.lineNo) + 2} : `;
        let dummyArr: any = [];
        for (let [key] of Object.entries(err)) {
            if (key !== "lineNo" && err[key] !== "") {
                dummyArr.push(err[key]);
            }
        }
        if (dummyArr?.length > 0) {
            let str = "";
            for (let i = 0; i < dummyArr.length; i++) {
                if (i === 0) {
                    if (dummyArr.length === 1) {
                        str = str.concat(`${dummyArr[i]}.`);
                    } else if (dummyArr.length === 2) {
                        str = str.concat(`${dummyArr[i]}`);
                    } else {
                        str = str.concat(`${dummyArr[i]}, `);
                    }
                } else if (i < dummyArr.length - 2) {
                    str = str.concat(`${dummyArr[i]}, `);
                } else if (i === dummyArr.length - 2) {
                    str = str.concat(`${dummyArr[i]}`);
                } else if (i > dummyArr.length - 2) {
                    str = str.concat(` and ${dummyArr[i]}.`);
                }
            }
            finalStr = finalStr.concat(str);
            return finalStr;
        }
        return ""
    });

    if (warningArray.length > 0) {
        setWarningDisplayMsg(warningArray);
    }
};

export const extractFilesWithJobIdAndCreatedAt = (filesArr: any[], alreadyUploaded: Boolean): string[] => {
    return filesArr.reduce((resultArr: string[], item: any) => {
        if (item?.filename) {
            let obj: any = {}
            obj.filename = item.filename
            obj.jobId = item.job_id
            obj.createdAt = item.created_at
            obj.type = 'filename'
            obj.alreadyUploaded = alreadyUploaded

            if (item?.status === 'completed') {
                obj.fileUploadStatus = true
            } else {
                obj.fileUploadStatus = false
            }

            resultArr.push(obj);
        }

        if (item?.audio_descriptor_filename) {
            let obj: any = {}
            obj.filename = item.audio_descriptor_filename
            obj.jobId = item.job_id
            obj.createdAt = item.created_at
            obj.type = 'audioDescriptorFilename'
            obj.alreadyUploaded = alreadyUploaded
            resultArr.push(obj);
        }

        if (item?.thumbnail_filename) {
            let obj: any = {}
            obj.filename = item.thumbnail_filename
            obj.jobId = item.job_id
            obj.createdAt = item.created_at
            obj.type = 'thumbnailFilename'
            obj.alreadyUploaded = alreadyUploaded
            resultArr.push(obj);
        }

        if (item?.nav_xml_filename) {
            let obj: any = {}
            obj.filename = item.nav_xml_filename
            obj.jobId = item.job_id
            obj.createdAt = item.created_at
            obj.type = 'navXMLFileName'
            obj.alreadyUploaded = alreadyUploaded
            resultArr.push(obj);
        }

        if (item?.caption_filenames && item.caption_filenames.length > 0) {
            item?.caption_filenames.split(';').forEach((captionFile: any) => {
                let obj: any = {}
                obj.filename = captionFile
                obj.jobId = item.job_id
                obj.createdAt = item.created_at
                obj.type = 'captionFileNames'
                obj.alreadyUploaded = alreadyUploaded
                resultArr.push(obj);
            })
        }

        if (item?.other_filenames && item.other_filenames.length > 0) {
            item?.other_filenames.split(';').forEach((otherFile: any) => {
                let obj: any = {}
                obj.filename = otherFile
                obj.jobId = item.job_id
                obj.createdAt = item.created_at
                obj.type = 'otherFileNames'
                obj.alreadyUploaded = alreadyUploaded
                resultArr.push(obj);
            })
        }
        return resultArr;
    }, []);
}

export const extractNotFoundFiles = (filesArr: any[]): string[] => {
    return filesArr.reduce((resultArr: string[], item: any) => {
        if (item?.filename) {
            resultArr.push(item.filename);
        }

        if (item?.audioDescriptorFilename) {
            resultArr.push(item.audioDescriptorFilename);
        }

        if (item?.thumbnailFilename) {
            resultArr.push(item.thumbnailFilename);
        }

        if (item?.navXMLFileName) {
            resultArr.push(item.navXMLFileName);
        }

        if (item?.captionFileNames && item.captionFileNames.length > 0) {
            resultArr.push(...item.captionFileNames);
        }

        if (item?.otherFileNames && item.otherFileNames.length > 0) {
            resultArr.push(...item.otherFileNames);
        }

        return resultArr;
    }, []);
}

export const extractPendingFiles = (filesArr: any[]): string[] => {
    let resultArr: string[] = [];

    filesArr.forEach((item) => {
        if (item.filename !== "" && item.status !== "completed") {
            resultArr.push(item.filename);
        }

        if (item.audio_descriptor_filename !== "" && item.audio_descriptor_status !== "completed") {
            resultArr.push(item.audio_descriptor_filename);
        }

        if (item.thumbnail_filename !== "" && item.thumbnail_status !== "completed") {
            resultArr.push(item.thumbnail_filename);
        }

        if (item.nav_xml_filename !== "" && item.nav_status !== "completed") {
            resultArr.push(item.nav_xml_filename);
        }

        if (item.caption_filenames !== "") {
            const captionFileStatusArr = item?.caption_status?.split(",");
            captionFileStatusArr.forEach((captionItem: string) => {
                if (!captionItem.split(".")[1].includes("completed")) {
                    resultArr.push(captionItem.substring(0, captionItem.lastIndexOf('_')));

                }
            });
        }

        if (item.other_filenames !== "") {
            const otherFileStatusArr = item?.other_status?.split(",");
            otherFileStatusArr.forEach((otherItem: string) => {
                if (!otherItem.split(".")[1].includes("completed")) {
                    resultArr.push(otherItem.substring(0, otherItem.lastIndexOf('_')));
                }
            });
        }
    });

    return resultArr;
}

export const extractProtocol = (objData: Array<any>) => {
    if (objData.length > 0) {
        return objData[0]?.Filename?.includes("s3://") || objData[0]?.Filename?.includes("S3://") ? "s3" : "https"
    } else return ''
}

export const checkForValidPlaceHolderValue = (objData: Array<any>, setInvalidPlaceholderErrMsg: any, setPlaceholderErr: any) => {
    let placeHolderArr: any[] = [];
    //checks if placeholder value contains 'Y', 'N' or undefined and push it into array otherwise.
    if (objData.length > 0) {
        objData.forEach((item: any, index: any) => {
            if (!(item['Placeholder'] === 'Y' || item['Placeholder'] === 'N' || item['Placeholder'] === undefined))
                placeHolderArr.push(index + 2);
        })
    }
    //concatinating error msg with the index
    let dummyArr: any[] = [];
    for (let value of placeHolderArr) {
        let finalStr = `Row : `;
        finalStr = finalStr.concat(value);
        dummyArr.push(finalStr);
    }
    setInvalidPlaceholderErrMsg(dummyArr);

    if (dummyArr?.length > 0) {
        setPlaceholderErr(true);
        return false;
    } else {
        return true;
    }
}